export const messageKeys = {
  // DEBUG
  D9001: 'D9001',
  // INFO
  I1001: 'I1001',
  I1002: 'I1002',
  I1003: 'I1003',
  I1004: 'I1004',
  I1005: 'I1005',
  // WARN
  W2001: 'W2001',
  W2002: 'W2002',
  // ERROR
  E3000: 'E3000',
  E3001: 'E3001',
  E3002: 'E3002',
  E3003: 'E3003',
  E3004: 'E3004',
  E3005: 'E3005',
  E3006: 'E3006',
  E3007: 'E3007',
  E3008: 'E3008',
  E3009: 'E3009',
  E3010: 'E3010',
  E3011: 'E3011',
  E3012: 'E3012',
  E3013: 'E3013',
  E3014: 'E3014',
  E3015: 'E3015',
  E3016: 'E3016',
  E3017: 'E3017',
  E3018: 'E3018',
  E3019: 'E3019',
  E3020: 'E3020',
  E3021: 'E3021',
  E3022: 'E3022',
};
const messages = {
  // DEBUG
  D9001: '共通DEBUG_001です',
  // INFO
  I1001: 'method-start',
  I1002: 'method-end',
  I1003: 'ログイン成功',
  I1004: 'S3にファイルがアップロードされました',
  I1005: 'S3のファイルを削除しました',
  // WARN
  W2001: '担当者マスタにログインIDと合致するユーザーデータが存在しません',
  W2002: '不正なアクセスです',
  // ERROR
  E3000: 'ログメッセージを取得できませんでした',
  E3001: '予期せぬエラーが発生しました',
  E3002: 'データ登録APIの実行でエラーが発生しました',
  E3003: 'データ取得APIの実行でエラーが発生しました',
  E3004: 'データ更新APIの実行でエラーが発生しました',
  E3005: 'データ削除APIの実行でエラーが発生しました',
  E3006: 'AppSyncのAPIでエラーが発生しました',
  E3007: 'Cognitoユーザー作成のAPIでエラーが発生しました',
  E3008: 'メール送信でエラーが発生しました',
  E3009: 'Lamda関数でのSQL実行でステータスエラーが発生しました',
  E3010: 'AppSyncのSQL実行でエラーが発生しました',
  E3011: 'ユーザー情報取得でexceptionが発生しました',
  E3012: 'Amplifyの実行でexceptionが発生しました',
  E3013: '帳票出力でエラーが発生しました',
  E3014: 'Cognitoユーザーメールを更新のAPIでエラーが発生しました',
  E3015: 'データの解析でエラーが発生しました',
  E3016: 'ユーザーのロールバックでエラーが発生しました',
  E3017: 'トランザクションAPIの実行でエラーが発生しました',
  E3018: 'ユーザーテーブルにデータが存在しませんでした',
  E3019: 'ストレージからのURL取得でエラーが発生しました',
  E3020: 'S3へのファイルアップロードに失敗しました',
  E3021: 'S3のファイル削除に失敗しました',
  E3022: 'データのダウンロードでエラーが発生しました',
};

export const getLogMessage = (messageKey) => {
  return messages[messageKey];
};
