/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sequenceIncrement = /* GraphQL */ `
  mutation SequenceIncrement($Type: Int!) {
    sequenceIncrement(Type: $Type) {
      Type
      CurrentNumber
    }
  }
`;
export const deleteInvoiceFromS3 = /* GraphQL */ `
  mutation DeleteInvoiceFromS3(
    $deleteInvoiceFromS3Input: DeleteInvoiceFromS3Input!
  ) {
    deleteInvoiceFromS3(deleteInvoiceFromS3Input: $deleteInvoiceFromS3Input) {
      statusCode
      body
    }
  }
`;
export const deleteCommissionFromS3 = /* GraphQL */ `
  mutation DeleteCommissionFromS3(
    $deleteCommissionFromS3Input: DeleteCommissionFromS3Input!
  ) {
    deleteCommissionFromS3(
      deleteCommissionFromS3Input: $deleteCommissionFromS3Input
    ) {
      statusCode
      body
    }
  }
`;
export const downloadCommissionFromS3 = /* GraphQL */ `
  mutation DownloadCommissionFromS3(
    $downloadCommissionFromS3Input: DownloadCommissionFromS3Input!
  ) {
    downloadCommissionFromS3(
      downloadCommissionFromS3Input: $downloadCommissionFromS3Input
    ) {
      statusCode
      body
    }
  }
`;
export const downloadinvoicefroms3 = /* GraphQL */ `
  mutation Downloadinvoicefroms3(
    $downloadinvoicefroms3Input: Downloadinvoicefroms3Input!
  ) {
    downloadinvoicefroms3(
      downloadinvoicefroms3Input: $downloadinvoicefroms3Input
    ) {
      statusCode
      body
    }
  }
`;
export const preparedownload = /* GraphQL */ `
  mutation Preparedownload($preparedownloadInput: PreparedownloadInput!) {
    preparedownload(preparedownloadInput: $preparedownloadInput) {
      statusCode
      body
    }
  }
`;
export const createOperationLogs = /* GraphQL */ `
  mutation CreateOperationLogs(
    $input: CreateOperationLogsInput!
    $condition: ModelOperationLogsConditionInput
  ) {
    createOperationLogs(input: $input, condition: $condition) {
      LogID
      LogLevel
      UserNo
      Message
      Component
      Function
      Param
      Error
      UserAgent
      Date
      Time
    }
  }
`;
export const updateOperationLogs = /* GraphQL */ `
  mutation UpdateOperationLogs(
    $input: UpdateOperationLogsInput!
    $condition: ModelOperationLogsConditionInput
  ) {
    updateOperationLogs(input: $input, condition: $condition) {
      LogID
      LogLevel
      UserNo
      Message
      Component
      Function
      Param
      Error
      UserAgent
      Date
      Time
    }
  }
`;
export const deleteOperationLogs = /* GraphQL */ `
  mutation DeleteOperationLogs(
    $input: DeleteOperationLogsInput!
    $condition: ModelOperationLogsConditionInput
  ) {
    deleteOperationLogs(input: $input, condition: $condition) {
      LogID
      LogLevel
      UserNo
      Message
      Component
      Function
      Param
      Error
      UserAgent
      Date
      Time
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      UserNo
      Type
      UnionistNo
      SalesNo
      LoginID
      Name
      EMail
      IsSendEmail
      IsDeleted
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      UserNo
      Type
      UnionistNo
      SalesNo
      LoginID
      Name
      EMail
      IsSendEmail
      IsDeleted
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      UserNo
      Type
      UnionistNo
      SalesNo
      LoginID
      Name
      EMail
      IsSendEmail
      IsDeleted
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const createSales = /* GraphQL */ `
  mutation CreateSales(
    $input: CreateSalesInput!
    $condition: ModelSalesConditionInput
  ) {
    createSales(input: $input, condition: $condition) {
      SalesNo
      Name
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const updateSales = /* GraphQL */ `
  mutation UpdateSales(
    $input: UpdateSalesInput!
    $condition: ModelSalesConditionInput
  ) {
    updateSales(input: $input, condition: $condition) {
      SalesNo
      Name
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const deleteSales = /* GraphQL */ `
  mutation DeleteSales(
    $input: DeleteSalesInput!
    $condition: ModelSalesConditionInput
  ) {
    deleteSales(input: $input, condition: $condition) {
      SalesNo
      Name
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const createCommissionHistories = /* GraphQL */ `
  mutation CreateCommissionHistories(
    $input: CreateCommissionHistoriesInput!
    $condition: ModelCommissionHistoriesConditionInput
  ) {
    createCommissionHistories(input: $input, condition: $condition) {
      CommissionID
      SalesNo
      Period
      Type
      RegistrationTime
      FilePath
      DLStatus
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const updateCommissionHistories = /* GraphQL */ `
  mutation UpdateCommissionHistories(
    $input: UpdateCommissionHistoriesInput!
    $condition: ModelCommissionHistoriesConditionInput
  ) {
    updateCommissionHistories(input: $input, condition: $condition) {
      CommissionID
      SalesNo
      Period
      Type
      RegistrationTime
      FilePath
      DLStatus
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const deleteCommissionHistories = /* GraphQL */ `
  mutation DeleteCommissionHistories(
    $input: DeleteCommissionHistoriesInput!
    $condition: ModelCommissionHistoriesConditionInput
  ) {
    deleteCommissionHistories(input: $input, condition: $condition) {
      CommissionID
      SalesNo
      Period
      Type
      RegistrationTime
      FilePath
      DLStatus
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const createUnionists = /* GraphQL */ `
  mutation CreateUnionists(
    $input: CreateUnionistsInput!
    $condition: ModelUnionistsConditionInput
  ) {
    createUnionists(input: $input, condition: $condition) {
      UnionistNo
      Name
      SalesNo
      IsSendMail
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const updateUnionists = /* GraphQL */ `
  mutation UpdateUnionists(
    $input: UpdateUnionistsInput!
    $condition: ModelUnionistsConditionInput
  ) {
    updateUnionists(input: $input, condition: $condition) {
      UnionistNo
      Name
      SalesNo
      IsSendMail
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const deleteUnionists = /* GraphQL */ `
  mutation DeleteUnionists(
    $input: DeleteUnionistsInput!
    $condition: ModelUnionistsConditionInput
  ) {
    deleteUnionists(input: $input, condition: $condition) {
      UnionistNo
      Name
      SalesNo
      IsSendMail
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const createCards = /* GraphQL */ `
  mutation CreateCards(
    $input: CreateCardsInput!
    $condition: ModelCardsConditionInput
  ) {
    createCards(input: $input, condition: $condition) {
      CardID
      UnionistNo
      SalesNo
      CardType
      IsDeleted
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const updateCards = /* GraphQL */ `
  mutation UpdateCards(
    $input: UpdateCardsInput!
    $condition: ModelCardsConditionInput
  ) {
    updateCards(input: $input, condition: $condition) {
      CardID
      UnionistNo
      SalesNo
      CardType
      IsDeleted
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const deleteCards = /* GraphQL */ `
  mutation DeleteCards(
    $input: DeleteCardsInput!
    $condition: ModelCardsConditionInput
  ) {
    deleteCards(input: $input, condition: $condition) {
      CardID
      UnionistNo
      SalesNo
      CardType
      IsDeleted
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const createCardTypeHistories = /* GraphQL */ `
  mutation CreateCardTypeHistories(
    $input: CreateCardTypeHistoriesInput!
    $condition: ModelCardTypeHistoriesConditionInput
  ) {
    createCardTypeHistories(input: $input, condition: $condition) {
      Period
      UnionistNoCardType
      UnionistNo
      CardType
      SalesNo
      RegistrationTime
      FilePath
      UnionistStatus
      MailStatus
      ProxyStatus
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const updateCardTypeHistories = /* GraphQL */ `
  mutation UpdateCardTypeHistories(
    $input: UpdateCardTypeHistoriesInput!
    $condition: ModelCardTypeHistoriesConditionInput
  ) {
    updateCardTypeHistories(input: $input, condition: $condition) {
      Period
      UnionistNoCardType
      UnionistNo
      CardType
      SalesNo
      RegistrationTime
      FilePath
      UnionistStatus
      MailStatus
      ProxyStatus
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const deleteCardTypeHistories = /* GraphQL */ `
  mutation DeleteCardTypeHistories(
    $input: DeleteCardTypeHistoriesInput!
    $condition: ModelCardTypeHistoriesConditionInput
  ) {
    deleteCardTypeHistories(input: $input, condition: $condition) {
      Period
      UnionistNoCardType
      UnionistNo
      CardType
      SalesNo
      RegistrationTime
      FilePath
      UnionistStatus
      MailStatus
      ProxyStatus
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const createCarousel = /* GraphQL */ `
  mutation CreateCarousel(
    $input: CreateCarouselInput!
    $condition: ModelCarouselConditionInput
  ) {
    createCarousel(input: $input, condition: $condition) {
      id
      StartDate
      EndDate
      Sequence
      Body
      Link
      ImgPath
      SequenceSortFlag
      Created
      CreatedBy
      Updated
      UpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateCarousel = /* GraphQL */ `
  mutation UpdateCarousel(
    $input: UpdateCarouselInput!
    $condition: ModelCarouselConditionInput
  ) {
    updateCarousel(input: $input, condition: $condition) {
      id
      StartDate
      EndDate
      Sequence
      Body
      Link
      ImgPath
      SequenceSortFlag
      Created
      CreatedBy
      Updated
      UpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteCarousel = /* GraphQL */ `
  mutation DeleteCarousel(
    $input: DeleteCarouselInput!
    $condition: ModelCarouselConditionInput
  ) {
    deleteCarousel(input: $input, condition: $condition) {
      id
      StartDate
      EndDate
      Sequence
      Body
      Link
      ImgPath
      SequenceSortFlag
      Created
      CreatedBy
      Updated
      UpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createUnionistsNews = /* GraphQL */ `
  mutation CreateUnionistsNews(
    $input: CreateUnionistsNewsInput!
    $condition: ModelUnionistsNewsConditionInput
  ) {
    createUnionistsNews(input: $input, condition: $condition) {
      id
      Date
      Time
      UnionistNo
      NewsId
      Title
      Body
      Link
      NotificationEmailSendable
      SentNotificationEmail
      DateTimeNewsIdUnionistNoSortFlag
      Created
      CreatedBy
      Updated
      UpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateUnionistsNews = /* GraphQL */ `
  mutation UpdateUnionistsNews(
    $input: UpdateUnionistsNewsInput!
    $condition: ModelUnionistsNewsConditionInput
  ) {
    updateUnionistsNews(input: $input, condition: $condition) {
      id
      Date
      Time
      UnionistNo
      NewsId
      Title
      Body
      Link
      NotificationEmailSendable
      SentNotificationEmail
      DateTimeNewsIdUnionistNoSortFlag
      Created
      CreatedBy
      Updated
      UpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnionistsNews = /* GraphQL */ `
  mutation DeleteUnionistsNews(
    $input: DeleteUnionistsNewsInput!
    $condition: ModelUnionistsNewsConditionInput
  ) {
    deleteUnionistsNews(input: $input, condition: $condition) {
      id
      Date
      Time
      UnionistNo
      NewsId
      Title
      Body
      Link
      NotificationEmailSendable
      SentNotificationEmail
      DateTimeNewsIdUnionistNoSortFlag
      Created
      CreatedBy
      Updated
      UpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createNews = /* GraphQL */ `
  mutation CreateNews(
    $input: CreateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    createNews(input: $input, condition: $condition) {
      id
      Date
      Time
      Title
      Body
      Link
      DateTimeSortFlag
      Created
      CreatedBy
      Updated
      UpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateNews = /* GraphQL */ `
  mutation UpdateNews(
    $input: UpdateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    updateNews(input: $input, condition: $condition) {
      id
      Date
      Time
      Title
      Body
      Link
      DateTimeSortFlag
      Created
      CreatedBy
      Updated
      UpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteNews = /* GraphQL */ `
  mutation DeleteNews(
    $input: DeleteNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    deleteNews(input: $input, condition: $condition) {
      id
      Date
      Time
      Title
      Body
      Link
      DateTimeSortFlag
      Created
      CreatedBy
      Updated
      UpdatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createEmailTemplates = /* GraphQL */ `
  mutation CreateEmailTemplates(
    $input: CreateEmailTemplatesInput!
    $condition: ModelEmailTemplatesConditionInput
  ) {
    createEmailTemplates(input: $input, condition: $condition) {
      TemplateType
      Subject
      Body
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const updateEmailTemplates = /* GraphQL */ `
  mutation UpdateEmailTemplates(
    $input: UpdateEmailTemplatesInput!
    $condition: ModelEmailTemplatesConditionInput
  ) {
    updateEmailTemplates(input: $input, condition: $condition) {
      TemplateType
      Subject
      Body
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const deleteEmailTemplates = /* GraphQL */ `
  mutation DeleteEmailTemplates(
    $input: DeleteEmailTemplatesInput!
    $condition: ModelEmailTemplatesConditionInput
  ) {
    deleteEmailTemplates(input: $input, condition: $condition) {
      TemplateType
      Subject
      Body
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const createAutoIncrement = /* GraphQL */ `
  mutation CreateAutoIncrement(
    $input: CreateAutoIncrementInput!
    $condition: ModelAutoIncrementConditionInput
  ) {
    createAutoIncrement(input: $input, condition: $condition) {
      Type
      CurrentNumber
    }
  }
`;
export const updateAutoIncrement = /* GraphQL */ `
  mutation UpdateAutoIncrement(
    $input: UpdateAutoIncrementInput!
    $condition: ModelAutoIncrementConditionInput
  ) {
    updateAutoIncrement(input: $input, condition: $condition) {
      Type
      CurrentNumber
    }
  }
`;
export const deleteAutoIncrement = /* GraphQL */ `
  mutation DeleteAutoIncrement(
    $input: DeleteAutoIncrementInput!
    $condition: ModelAutoIncrementConditionInput
  ) {
    deleteAutoIncrement(input: $input, condition: $condition) {
      Type
      CurrentNumber
    }
  }
`;
export const createDownloadWork = /* GraphQL */ `
  mutation CreateDownloadWork(
    $input: CreateDownloadWorkInput!
    $condition: ModelDownloadWorkConditionInput
  ) {
    createDownloadWork(input: $input, condition: $condition) {
      DownloadID
      StatusCode
      S3Key
      Body
      UpdateCardTypeHistories
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const updateDownloadWork = /* GraphQL */ `
  mutation UpdateDownloadWork(
    $input: UpdateDownloadWorkInput!
    $condition: ModelDownloadWorkConditionInput
  ) {
    updateDownloadWork(input: $input, condition: $condition) {
      DownloadID
      StatusCode
      S3Key
      Body
      UpdateCardTypeHistories
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const deleteDownloadWork = /* GraphQL */ `
  mutation DeleteDownloadWork(
    $input: DeleteDownloadWorkInput!
    $condition: ModelDownloadWorkConditionInput
  ) {
    deleteDownloadWork(input: $input, condition: $condition) {
      DownloadID
      StatusCode
      S3Key
      Body
      UpdateCardTypeHistories
      Created
      CreatedBy
      Updated
      UpdatedBy
    }
  }
`;
export const executeTransaction = /* GraphQL */ `
  mutation ExecuteTransaction($items: [TransactionItem!]!) {
    executeTransaction(items: $items) {
      statusCode
      body
    }
  }
`;
export const uploadInvoice = /* GraphQL */ `
  mutation UploadInvoice($filePathList: [String!]!, $uuid: String) {
    uploadInvoice(filePathList: $filePathList, uuid: $uuid) {
      statusCode
      body
    }
  }
`;
export const updateCommission = /* GraphQL */ `
  mutation UpdateCommission($filePathList: [String!]!, $uuid: String) {
    updateCommission(filePathList: $filePathList, uuid: $uuid) {
      statusCode
      body
    }
  }
`;
export const deleteFilesByFolder = /* GraphQL */ `
  mutation DeleteFilesByFolder($folder: String!) {
    deleteFilesByFolder(folder: $folder) {
      statusCode
      body
    }
  }
`;
export const runUnzip = /* GraphQL */ `
  mutation RunUnzip($filePath: String!, $comeFrom: String!, $uuid: String!) {
    runUnzip(filePath: $filePath, comeFrom: $comeFrom, uuid: $uuid) {
      statusCode
      body
    }
  }
`;
