import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';
import VueGtag from 'vue-gtag';
import { ANALYTICS_ID } from '@/assets/js/const';

import aws_exports from './aws-exports';
import Amplify from 'aws-amplify';
Amplify.configure(aws_exports);

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: ANALYTICS_ID },
  autoTracking: false,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created: () => document.documentElement.setAttribute('lang', 'ja'),
}).$mount('#app');
