<template>
  <div>
    <!-- v-app Vuetifyを利用するための必須タグ -->
    <v-app>
      <Loading />
      <Navigation />
      <!-- メインコンテンツ -->
      <v-main>
        <router-view />
      </v-main>
      <CommonDialog ref="confirm" />
    </v-app>
  </div>
</template>
<script>
import Loading from '@/components/Loading';
import Navigation from '@/components/Navigation';
import CommonDialog from '@/components/dialog/CommonDialog.vue';
export default {
  components: {
    Loading,
    Navigation,
    CommonDialog,
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm;
  },
};
</script>
