import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import ja from 'vuetify/es5/locale/ja';

export default new Vuetify({
  lang: {
    locales: { ja },
    current: 'ja',
  },
  theme: {
    themes: {
      light: {
        // ロゴカラー
        primary: '#036',
        secondary: '#00a7ba',
        success: '#4caf50',
        info: '#2196f3',
        warning: '#fb8c00',
        error: '#ff5252',
      },
    },
  },
});
