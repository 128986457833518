import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

/**
 * YYYYMMDDフォーマットの日付を返却
 * @returns YYYYMMDDフォーマットの日付
 */
export const getDateYYYYMMDD = () => {
  return dayjs().format('YYYYMMDD');
};

/**
 * HHmmssフォーマットの時刻を返却
 * @returns HHmmssフォーマットの時刻
 */
export const getTimeHHmmss = () => {
  return dayjs().format('HHmmss');
};

/**
 * YYYY-MM-DD HH:mm:ssフォーマットの時刻を返却
 * @returns HHmmssフォーマットの時刻
 */
export const getTimestamp = () => {
  return dayjs().format('YYYY-MM-DD HH:mm:ss');
};

/**
 * 年月日をYYYYMMDD形式の8桁の数値にして返却
 * @param {Date} date - 変換するDate
 * @returns {Number} YYYYMMDD形式の8桁の数値
 */
export const getNumberYYYYMMDD = (date = new Date()) => {
  return date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();
};

/**
 * 時分秒をHHmmss形式の6桁の数値にして返却
 * @param {*} date - 変換するDate
 * @returns {Number} HHmmss形式の6桁の数値
 */
export const getNumberHHmmss = (date = new Date()) => {
  return date.getHours() * 10000 + date.getMinutes() * 100 + date.getSeconds();
};

/**
 * 時分秒をHHmm形式の4桁の数値にして返却
 * @param {*} date - 変換するDate
 * @returns {Number} HHmm形式の6桁の数値
 */
export const getNumberHHmm = (date = new Date()) => {
  return date.getHours() * 100 + date.getMinutes();
};
