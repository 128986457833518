import Vue from 'vue';
import Vuex from 'vuex';
import { USER_TYPES } from '@/assets/js/const';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    loading: false,
    showNav: true,
  },
  getters: {
    /**
     * userの更新はmixinに含めるため
     * 競合しない名称とする
     */
    $_user: (state) => state.user,
    $_isUserAdmin: (state) => state.user.Type === USER_TYPES.ADMIN,
    $_isUserSales: (state) => state.user.Type === USER_TYPES.SALES,
    $_isUserUnionist: (state) => state.user.Type === USER_TYPES.UNIONIST,

    loading: (state) => state.loading,
    showNav: (state) => state.showNav,
  },
  mutations: {
    setStateUser(state, user) {
      state.user = user;
    },
    setShowNav(state, payload) {
      state.showNav = payload;
    },
    /**
     * loadingの更新はmixinに含めるため
     * 競合しない名称とする
     */
    $_setLoading(state, payload) {
      state.loading = payload;
    },
  },
  actions: {},
  modules: {},
});
