<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      :persistent="options.fixDialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
      @keydown.enter="ok"
    >
      <v-card>
        <v-toolbar :color="alertType" dark dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4" v-html="message"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="ok"> OK </v-btn>
          <v-btn color="grey" text v-if="showCancel" @click.native="cancel"> キャンセル </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { DIALOG_TYPE } from '@/assets/js/const';
import { getClientMessage } from '@/assets/js/clientMessages';
export default {
  name: 'CommonDialog',
  data: () => ({
    dialog: false,
    dialogType: null,
    title: null,
    messageType: null,
    alertType: 'primary',
    message: null,
    options: {
      width: 300,
      zIndex: 2147483647,
      fixDialog: false,
    },
    optionsDefault: {
      width: 300,
      zIndex: 2147483647,
      fixDialog: false,
    },
    resolve: null,
    reject: null,
  }),
  computed: {
    showDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.dialog = value;
        if (value === false) {
          this.cancel();
        }
      },
    },
    showCancel() {
      return this.dialogType === DIALOG_TYPE.CONFIRM;
    },
  },
  methods: {
    open({ dialogType, title, messageKey, options = {}, messageReplace = null }) {
      console.log({
        dialogType: dialogType,
        title: title,
        messageKey: messageKey,
        options: options,
      });
      this.dialog = true;
      this.dialogType = dialogType;
      this.title = title;

      const { alertType, message } = getClientMessage(messageKey);
      this.alertType = alertType;
      if (messageReplace) {
        var tempMsg = String(message);
        messageReplace.forEach((item) => {
          tempMsg = tempMsg.replace(`{${item.key}}`, item.value);
        });
        this.message = tempMsg;
      } else {
        this.message = message;
      }
      this.options = Object.assign(this.options, this.optionsDefault);
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
