<template>
  <v-overlay :z-index="2147483646" :width="7" :value="$store.getters.loading">
    <v-progress-circular indeterminate size="64" :width="7"></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  name: 'loading',
};
</script>
